import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Logo from "../../../assets/images/loog.jpg";
import { authOperations } from "../../../state/ducs/auth";
import { cartOperations } from "../../../state/ducs/cart";
import "./index.css";

const Header = ({
  cartItems,
  removeFromCart,
  handleSectionRef,
  setHomePageSearchedResultProducts,
  signIn,
  logout,
  removeUser,
  userInfo,
  isAuthenticated,
}) => {
  const [selectedSCat, setSelectedSCat] = useState("");
  const [categories, setCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showSubmenu, setShowSubmenu] = useState(false);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format").required("Required"),
    password: Yup.string().required("Required"),
  });

  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      const response = await axios.post(
        "https://ap.jakhantakhan.com/api/user-login",
        values
      );
      console.log("Login successful", response.data);
      handleClose();
      navigate("/");
      // Handle successful login (e.g., redirect, save token, etc.)
    } catch (error) {
      console.error("Login failed", error);
      setErrors({ submit: "Invalid email or password" });
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    axios
      .get("https://ap.jakhantakhan.com/api/allcategories")
      .then(function (response) {
        const respData = response.data;
        respData.reverse();
        setCategories(respData);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const onSearchHandler = () => {
    fetch("https://ap.jakhantakhan.com/api/search?name=" + searchQuery)
      .then((response) => response.json())
      .then((data) => {
        setHomePageSearchedResultProducts(data.products);
      });
  };

  const navigate = useNavigate();

  const handleSCatChange = (e) => {
    setSelectedSCat(e.target.value);
  };

  let subtotalPrice = 0;
  for (let index = 0; index < cartItems.length; index++) {
    const element = cartItems[index];
    subtotalPrice += parseInt(element.price) * element.quantity;
  }

  const onViewCartHandler = () => {
    navigate("/viewPage");
  };

  const onCheckoutHandler = () => {
    navigate("/checkout");
  };

  const handleSearch = () => {
    onSearchHandler();
    handleSectionRef();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };
  const handleLogout = () => {
    logout();
    removeUser();
    navigate("/");
  };

  return (
    <>
      <div className="container-fluid px-3 px-md-5">
        <div className="row d-flex align-items-start">
          <div className="col-6 col-md-2 mb-3 mb-md-0">
            <Link to={"/"} className="logo">
              <img
                style={{ marginTop: "5px", maxWidth: "65%", height: "auto" }}
                src={Logo}
                alt=""
              />
            </Link>
          </div>
          <div
            className="col-12 col-md-6 d-flex align-items-center search-section valo"
            style={{
              marginTop: "1rem",
              borderRadius: "5px",
              marginBottom: "1rem",
              justifyContent: "center",
            }}
          >
            <div className="search-category">
              <select
                onChange={handleSCatChange}
                className="sCat form-select"
                name="sCat"
                id="sCat"
                aria-label="Search Category"
              >
                <option value="all">All Categories</option>
                {categories.length &&
                  categories.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
            <div style={{ padding: "4px" }} className="searchField flex-grow-1">
              <input
                type="text"
                placeholder="Search for products"
                name="search"
                id="search"
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={handleKeyPress}
                className="form-control"
                aria-label="Search Input"
              />
            </div>
            <div
              className="searchButton"
              onClick={handleSearch}
              role="button"
              tabIndex={0}
              aria-label="Search Button"
            >
              <i className="fa fa-search"></i>
              Search
            </div>
          </div>

          <div
            className={`col-6 col-md-4 ${
              isAuthenticated ? "lastcolum" : "lastcolum-not"
            }`}
          >
            <div className="d-flex justify-content-end align-items-center">
              {!isAuthenticated && (
                <Link
                  style={{ color: "none" }}
                  to="/login"
                  onClick={""}
                  className="d-flex align-items-center me-3"
                >
                  <div className="valo_aci">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="currentColor"
                      className="bi bi-person"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                    </svg>
                  </div>
                  <div
                    className="d-none d-md-block"
                    style={{ fontFamily: "auto" }}
                  >
                    <small className="d-block" style={{ fontSize: "0.75rem" }}>
                      Login
                    </small>
                    <span style={{ fontSize: "0.875rem", fontWeight: "bold" }}>
                      Account
                    </span>
                  </div>
                </Link>
              )}

              <div className="d-flex align-items-center me-3">
                <svg
                  className=""
                  width="28"
                  height="23"
                  viewBox="0 0 28 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M25.7442 2.10275C24.2909 0.752316 22.3676 0 20.3687 0C18.3697 0 16.4465 0.752316 14.9931 2.10275L14 3.0345L13.0069 2.10275C11.5572 0.743159 9.62821 -0.0103607 7.62554 0.000636744C5.62664 -0.00860296 3.7019 0.744876 2.25576 2.10275C1.54386 2.75756 0.976298 3.54931 0.588263 4.42889C0.200228 5.30847 0 6.25712 0 7.216C0 8.17488 0.200228 9.12353 0.588263 10.0031C0.976298 10.8827 1.54386 11.6744 2.25576 12.3293L13.2147 22.6921C13.3222 22.7846 13.4428 22.8612 13.5727 22.9194C13.8471 23.0269 14.1529 23.0269 14.4273 22.9194C14.5572 22.8612 14.6778 22.7846 14.7853 22.6921L25.7442 12.3293C26.4561 11.6744 27.0237 10.8827 27.4117 10.0031C27.7998 9.12353 28 8.17488 28 7.216C28 6.25712 27.7998 5.30847 27.4117 4.42889C27.0237 3.54931 26.4561 2.75756 25.7442 2.10275ZM25.7442 7.19327C25.7475 7.86547 25.6121 8.53132 25.3461 9.15048C25.0801 9.76963 24.6891 10.3292 24.1968 10.7953L14 20.4536L3.81473 10.7953C3.31485 10.3331 2.91647 9.77502 2.64415 9.15553C2.37183 8.53604 2.23134 7.86823 2.23134 7.19327C2.23134 6.51832 2.37183 5.85051 2.64415 5.23102C2.91647 4.61153 3.31485 4.05348 3.81473 3.59127C4.84037 2.62701 6.20703 2.09319 7.62554 2.10275C9.05112 2.09206 10.4254 2.62562 11.4595 3.59127L13.2147 5.2616C13.3222 5.3541 13.4428 5.43066 13.5727 5.48886C13.8471 5.59638 14.1529 5.59638 14.4273 5.48886C14.5572 5.43066 14.6778 5.3541 14.7853 5.2616L16.5521 3.59127C17.5777 2.62701 18.9444 2.09319 20.3629 2.10275C21.7885 2.09206 23.1628 2.62562 24.1968 3.59127C24.6933 4.0554 25.0886 4.6141 25.3586 5.23335C25.6286 5.8526 25.7676 6.51944 25.7673 7.19327H25.7442Z"
                    fill="#212529"
                  ></path>
                </svg>
                <div className="counter">0</div>
              </div>

              <div className="cart-info-wrapper">
                <div className="icon p-2 d-flex justify-content-start align-items-center">
                  <i className="fa fa-shopping-cart text-bold"></i>
                  {isAuthenticated && (
                    <>
                      <div className="counter">{cartItems.length}</div>
                      <div className="px-2 d-none d-md-block">
                        Your cart
                        <h6 className="cart-price text-bold">
                          ৳{subtotalPrice || "0.00"}
                        </h6>
                      </div>
                    </>
                  )}
                </div>

                <div className="cart-items-container">
                  {!cartItems.length ? (
                    <p className="p-3">Your shopping cart is empty</p>
                  ) : (
                    <ul className="list-unstyled">
                      <li className="content-item">
                        <table className="table table-striped mb-1">
                          <tbody>
                            {cartItems.map((product) => (
                              <tr key={product.id}>
                                <td className="text-center size-img-cart">
                                  <a className="img-thumb" href="">
                                    <img
                                      height={60}
                                      width={50}
                                      src={
                                        "https://ap.jakhantakhan.com/assets/images/products/" +
                                        product.photo
                                      }
                                      alt=""
                                    />
                                  </a>
                                </td>
                                <td className="text-left">
                                  {product.name.substr(0, 7)}
                                </td>
                                <td className="text-right">
                                  x{product.quantity}
                                </td>
                                <td className="text-right">
                                  ৳{product.price * product.quantity}
                                </td>
                                <td className="text-center">
                                  <button
                                    type="button"
                                    className="rmCart btn btn-danger btn-xs"
                                    onClick={() => removeFromCart(product)}
                                  >
                                    <i className="fa fa-trash-o"></i>
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </li>
                      <li>
                        <div className="py-1 mt-3 px-2 d-flex justify-content-between clear-fix">
                          <button
                            onClick={onViewCartHandler}
                            className="viewCartButton"
                          >
                            View Cart
                          </button>
                          <button
                            onClick={onCheckoutHandler}
                            className="checkoutButton"
                          >
                            Checkout
                          </button>
                        </div>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>

            <div className="logout">
              {isAuthenticated && (
                <div className="user-menu">
                  <div className="user-menu-button">
                    {userInfo.profileImage ? (
                      <img
                        src={userInfo.profileImage}
                        alt={userInfo.name}
                        className="user-image"
                      />
                    ) : (
                      <span>{userInfo.name}</span>
                    )}
                  </div>
                  <div className="user-submenu">
                    <ul>
                      <li>
                        <button
                          className="logout-button"
                          onClick={handleLogout}
                        >
                          Logout
                        </button>
                      </li>
                      {/* Add more submenu items if needed */}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="login-container">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, errors }) => (
                <Form>
                  <div className="mb-3">
                    <label htmlFor="email">Email Address *</label>
                    <Field
                      type="email"
                      id="email"
                      name="email"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="password">Password *</label>
                    <Field
                      type="password"
                      id="password"
                      name="password"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {errors.submit && (
                    <div className="error-message">{errors.submit}</div>
                  )}
                  <button
                    type="submit"
                    style={{ backgroundColor: "#b80ebb" }}
                    className="login-button"
                    disabled={isSubmitting}
                  >
                    Login
                  </button>
                </Form>
              )}
            </Formik>
            <div className="text-center mt-3">
              If you don't have an account,{" "}
              <Link
                onClick={handleClose}
                to="/register"
                style={{ color: "aqua" }}
              >
                Register
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cart,
    isAuthenticated: state.auth.token !== null,
    userInfo: state.auth.user,
  };
};

const mapDispatchToProps = {
  removeFromCart: cartOperations.removeFromCart,
  logout: authOperations.logout,
  removeUser: authOperations.removeAuthUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
