import axios from "axios";
import { Formik } from "formik";
import React from "react";
import { connect } from 'react-redux';
import * as Yup from "yup";
import "../../Components/LoginVendorRegister/Login";
import Myaccount from "../../Components/MyAccount/Myaccount";
import CartBannerComponent from "../../Components/ProdectBanner/CartBannerComponent";
import { authHeader } from "../../utils";
import "./Edit.css";

const Edit = ({ setlogin, userInfo }) => {
  const phoneRegExp = /^(?:\+?88)?01[13-9]\d{8}$/; //bd phone regex

  const registrationSchema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    phoneNumber: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
    address: Yup.string().required("Required"),
    country: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    zip: Yup.string().required("Required"),
    // password: Yup.string()
    //   .min(6, "Too Short!")
    //   .max(50, "Too Long!")
    //   .required("Required"),

    // password_confirmation: Yup.string().oneOf(
    //   [Yup.ref("password"), null],
    //   "Passwords must match"
    // ),
  });

  const handleRegistrationSubmit = (values, actions) => {
    console.log("regformvalues", values);
    //network request here
    const regPostObj= {
      name: values.firstName+" "+values.lastName,
      email: values.email,
      address: values.address,
      country: values.country,
      city: values.city,
      zip: values.zip,
      phone: values.phoneNumber,
      // password: values.password,
      // password_confirmation: values.password_confirmation
    }

    const regUrl= "https://ap.jakhantakhan.com/api/profile"

    axios.post(regUrl, regPostObj, {headers: authHeader()})
    .then((response)=> {
      if (response.status===200) {
        console.log(response);
        actions.resetForm();
        alert("Profile update successful");
      }
    })
    .catch((error)=> {
      console.log(error);
      alert("Profile update failure");
    });
  };

  return (
    <div>
      <CartBannerComponent title="Edit" />
      <div className="container">
        <div className="row content">
          <div className="col-md-9 decration">
            <h1>Edit Profile</h1>

            <Formik
              initialValues={{
                firstName: userInfo.name.split(" ")[0],
                lastName: userInfo.name.split(" ")[1],
                email: userInfo.email,
                phoneNumber: userInfo.phone,
                address: userInfo.address,
                country: userInfo.country,
                city: userInfo.city,
                zip: userInfo.zip,
                // password: "",
                // password_confirmation: ""
              }}
              validationSchema={registrationSchema}
              onSubmit={handleRegistrationSubmit}
            >
              {({
                errors,
                touched,
                handleSubmit,
                handleChange,
                handleBlur,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  <fieldset id="accounts">
                    <legend className="legender">Your Personal Details</legend>
                    <div className="form-group required">
                      <label
                        htmlFor="input-firstname"
                        className="col-sm-2 control-label"
                      >
                        First Name
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          name="firstName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.firstName}
                          placeholder="First Name"
                          id="input-firstname"
                          className="from-control"
                        />
                        {touched.firstName && errors.firstName && (
                          <div style={{ color: "red" }}>{errors.firstName}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-group required">
                      <label
                        htmlFor="input-lastname"
                        className="col-sm-2 control-label"
                      >
                        Last Name
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          name="lastName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.lastName}
                          placeholder="Last Name"
                          id="input-lastname"
                          className="from-control"
                        />
                        {touched.lastName && errors.lastName && (
                          <div style={{ color: "red" }}>{errors.lastName}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-group required">
                      <label
                        htmlFor="input-email"
                        className="col-sm-2 control-label"
                      >
                        E-Mail
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          placeholder="E-Mail"
                          id="input-email"
                          className="from-control"
                        />
                        {touched.email && errors.email && (
                          <div style={{ color: "red" }}>{errors.email}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-group required">
                      <label
                        htmlFor="input-telephone"
                        className="col-sm-2 control-label"
                      >
                        Phone Number
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          name="phoneNumber"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phoneNumber}
                          placeholder="Telephone"
                          id="input-telephone"
                          className="from-control"
                        />
                        {touched.phoneNumber && errors.phoneNumber && (
                          <div style={{ color: "red" }}>
                            {errors.phoneNumber}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group required">
                      <label
                        htmlFor="input-address"
                        className="col-sm-2 control-label"
                      >
                        Address
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          name="address"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.address}
                          placeholder="Address"
                          id="input-address"
                          className="from-control"
                        />
                        {touched.address && errors.address && (
                          <div style={{ color: "red" }}>{errors.address}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-group required">
                      <label
                        htmlFor="input-country"
                        className="col-sm-2 control-label"
                      >
                        Country
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          name="country"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.country}
                          placeholder="Country"
                          id="input-country"
                          className="from-control"
                        />
                        {touched.country && errors.country && (
                          <div style={{ color: "red" }}>{errors.country}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-group required">
                      <label
                        htmlFor="input-city"
                        className="col-sm-2 control-label"
                      >
                        City
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          name="city"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.city}
                          placeholder="City"
                          id="input-city"
                          className="from-control"
                        />
                        {touched.city && errors.city && (
                          <div style={{ color: "red" }}>{errors.city}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-group required">
                      <label
                        htmlFor="input-zip"
                        className="col-sm-2 control-label"
                      >
                        Zip
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          name="zip"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.zip}
                          placeholder="Zip"
                          id="input-zip"
                          className="from-control"
                        />
                        {touched.zip && errors.zip && (
                          <div style={{ color: "red" }}>{errors.zip}</div>
                        )}
                      </div>
                    </div>
                  </fieldset>
        

                  <div className="buttons">
                    <div className="pull-right">
                      <input
                        type="submit"
                        value={"Update"}
                        className="btn btn-primary"
                      />
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
          <Myaccount setlogin={setlogin} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps=(state)=> {
  return {
    userInfo: state.auth.user
  }
}

const mapDispatchToProps={}

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
