import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import image from "../../../src/assets/images/sdf.png";
import review from "../../assets/images/review.jpg";
import sizeChart from "../../assets/images/size-chart.jpg";
import { cartOperations } from "../../state/ducs/cart";
import { globalOperations } from "../../state/ducs/globalState";
import ProductCarousel from "../ProductCarousel";
import "./productDetailsMain.css";

const ProdectBannerCard = (props) => {
  const navigate= useNavigate();
  const images = [
    {
      small: "",
      medium: "",
      large: "https://ap.jakhantakhan.com/assets/images/products/"+props.productObj.photo,
      full: "https://ap.jakhantakhan.com/assets/images/products/"+props.productObj.photo,
    },
    // {
    //   small: "",
    //   medium: "",
    //   large: laptop,
    //   full: "",
    // },
    // {
    //   small: "",
    //   medium: "",
    //   large: laptop1,
    //   full: "",
    // },

    // {
    //   small: "",
    //   medium: "",
    //   large: laptop2,
    //   full: "",
    // },
  ];

  useEffect(() => {
    window.scrollTo(0, 400);
  }, []);

  const addToCartHandler = () => {


    const isAuthenticated = props.isAuthenticated; // Assuming you get this prop or from 
    
console.log(isAuthenticated);
    if (!isAuthenticated) {
      // Redirect to the login page if the user is not authenticated
      navigate('/login');
      return;
    }

    
    // check if other vendor products available in the cart
    const other_vendor_products= props.cartItems.filter(item=> item.vendor_name !== props.productObj.vendor_name);

    if (other_vendor_products.length) {
      alert("You can order single vendor product only at a time");
      return;
    }

    props.addToCart(props.productObj);
    props.showAddToCartModal();
  };

  const goVendor=()=> {
    // alert("Hello There");
    navigate("/vendorDetails/"+props.productObj.vendor_id);
  }

  return (
    <div>
      <div className="container mx-4 product-details-main" style={{margin:"10px"}}>
        <div className="row">
          <div className="col-md-6">
            <ProductCarousel media={images} />
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-5">
            <h1 className="cardTitle">{props.title}</h1>
            <div className="stars">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
            <h2>{props.price}</h2>
            <p>Brand: {props.brand}</p>
            <p>Product Code: {props.ProductCode}</p>
            <p>Availability: {props.Availability}</p>
            <p>Viewed: 10</p>

            <p>Qty: {props.productObj.quantity}</p>

            <div
              onClick={addToCartHandler} 
              type="button"
              class="btn "
            >
              Add to Cart
            </div>
            <div type="button" class="btn">
              Buy Now{" "}
            </div>
            <h6 style={{cursor: "pointer"}} onClick={goVendor}>Shop Name: {props.productObj.vendor_name}</h6>
            <h6>Share This</h6>
            <div className="d-flex align-items-center">
              <i
                class="fa fa-facebook-square"
                style={{
                  fontSize: "36px",
                  margin: "5px",
                  color: "#1877F2",
                  cursor: "pointer",
                }}
              ></i>
              <i
                class="fa fa-twitter"
                style={{
                  fontSize: "36px",
                  margin: "5px",
                  color: "#1D9BF0",
                  cursor: "pointer",
                }}
              ></i>
              <i
                class="fa fa-pinterest"
                style={{
                  fontSize: "36px",
                  margin: "5px",
                  color: "#B7081B",
                  cursor: "pointer",
                }}
              ></i>
              <i
                class="fa fa-instagram"
                style={{
                  fontSize: "36px",
                  margin: "5px",
                  color: "#FE205A",
                  cursor: "pointer",
                }}
              ></i>
            </div>
            <img className="mt-3" src={image} alt="" />
          </div>
        </div>
      </div>

      <div className="container p-5">
        <div className="row">
          <div className="col-md-8">
            <nav>
              <div class="nav nav-tabs customTabs" id="nav-tab" role="tablist">
                <button
                  class="nav-link active"
                  id="nav-home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-home"
                  type="button"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected="true"
                >
                  Description
                </button>
                <button
                  class="nav-link"
                  id="nav-profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-profile"
                  type="button"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="false"
                >
                  Reviews (0)
                </button>
                <button
                  class="nav-link"
                  id="nav-contact-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-contact"
                  type="button"
                  role="tab"
                  aria-controls="nav-contact"
                  aria-selected="false"
                >
                  Shipping Methods
                </button>
                <button
                  class="nav-link"
                  id="nav-size-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-size"
                  type="button"
                  role="tab"
                  aria-controls="nav-size"
                  aria-selected="false"
                >
                  Size Chart
                </button>
              </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
              <div
                class="tab-pane fade show active"
                id="nav-home"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
              >
                <h4>Product Description</h4>
                {props.productObj.description} SHOW MORE
              </div>
              <div
                class="tab-pane fade"
                id="nav-profile"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
              >
                There are no reviews for this product. Write a review Your Name
                Your Review Note: HTML is not translated! Rating Bad Good
              </div>
              <div
                class="tab-pane fade"
                id="nav-contact"
                role="tabpanel"
                aria-labelledby="nav-contact-tab"
              >
                When you order from opencartworks.com, you will receive a
                confirmation email. Once your order is shipped, you will be
                emailed the tracking information for your order's shipment. You
                can choose your preferred shipping method on the Order
                Information page during the checkout process. The total time it
                takes to receive your order is shown below:
                <img className="img-fluid w-100 my-3" src={review} alt="" />
                The total delivery time is calculated from the time your order
                is placed until the time it is delivered to you. Total delivery
                time is broken down into processing time and shipping time.
                Processing time: The time it takes to prepare your item(s) to
                ship from our warehouse. This includes preparing your items,
                performing quality checks, and packing for shipment. Shipping
                time: The time for your item(s) to tarvel from our warehouse to
                your destination. Shipping from your local warehouse is
                significantly faster. Some charges may apply. In addition, the
                transit time depends on where you're located and where your
                package comes from. If you want to know more information, please
                contact the customer service. We will settle your problem as
                soon as possible. Enjoy shopping!
              </div>
              <div
                class="tab-pane fade"
                id="nav-size"
                role="tabpanel"
                aria-labelledby="nav-size-tab"
              >
                <img className="img-fluid w-100" src={sizeChart} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { cartItems: state.cart,
    isAuthenticated: state.auth.token !==null,
   };


};

const mapDispatchToProps = {
  addToCart: cartOperations.addToCart,
  showAddToCartModal: globalOperations.showAddToCartModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProdectBannerCard);
