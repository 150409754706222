import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import BestSellerCard from "./BestSellerCard";
import "./index.css";

function BestSeller({ t, vendor_name }) {
  const [bestSellerProduct, setbestSellerProduct] = useState([]);
  useEffect(() => {
    fetch("https://ap.jakhantakhan.com/api/best-seller?vendor_name="+vendor_name)
      .then((response) => response.json())
      .then((data) => setbestSellerProduct(data));
  }, [vendor_name]);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };
  return (
    <div className="container p-4">
      <h1>{t("bseller")} </h1>
      <div className="slick-container">

        <Slider {...settings}>
          {bestSellerProduct.length && bestSellerProduct.slice(0, 8).map(product => (
            <div className="slick-item-container">
              <BestSellerCard
                productObj={product}
                t={t}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

const mapStateToProps=(state)=> {
  return {
    vendor_name: state.globalState.selectedProductVendorName,
  }
}

const mapDispatchToProps={}



export default connect(mapStateToProps, mapDispatchToProps)(BestSeller);
