import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { numberWithCommas } from '../../utils';
import ProdectDetailsMain from "./ProdectDetailsMain";
import "./index.css"; // Make sure this file contains your responsive styles

const ProdectBanners = () => {
  const { productId } = useParams();
  const [productDetails, setProductDetails] = useState(null);

  useEffect(() => {
    fetch("https://ap.jakhantakhan.com/api/products/get/" + productId)
      .then((response) => response.json())
      .then((data) => setProductDetails(data));
  }, [productId]);

  return (
    <>
      {productDetails ? (
        <>
          {/* <CartBannerComponent title={productDetails.name} /> */}
          <div className="container">
            <ProdectDetailsMain
              title={productDetails.name}
              price={"৳" + numberWithCommas(productDetails.price)}
              brand={productDetails.model}
              ProductCode="Product 19"
              Availability="In Stock"
              Quality="0"
              productObj={productDetails}
            />
          </div>
        </>
      ) : (
        <h1>Loading...</h1>
      )}
    </>
  );
};

export default ProdectBanners;
