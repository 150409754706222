import React from "react";
import "./index.css";

class ProductCarousel extends React.Component {
  state = {
    photos: [],
    active: 0,
    zoomDisplay: "",
    PageX: 0,
    PageY: 0,
  };

  static getDerivedStateFromProps({ media }) {
    let photos = ["http://placecorgi.com/600/600"];

    if (media.length) {
      photos = media.map((photoObj) => photoObj);
    }

    return { photos };
  }

  handleIndexClick = (event) => {
    this.setState({
      active: +event.target.dataset.index,
    });
  };

  handleMouseOver = () => {
    this.setState({
      zoomDisplay: "block",
    });
  };

  handleMouseLeave = () => {
    this.setState({
      zoomDisplay: "",
      PageX: 0,
      PageY: 0,
    });
  };

  handleMouseMove = (event) => {
    const { left, top, width, height } = event.target.getBoundingClientRect();
    const scrollPos = window.pageYOffset;

    const x = ((event.pageX - left) / width) * 100;
    const y = ((event.pageY - scrollPos - top) / height) * 100;

    this.setState({
      PageX: x,
      PageY: y,
    });
  };

  render() {
    const { photos, active, zoomDisplay, PageX, PageY } = this.state;
    return (
      <>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8">
              <div className="product-carousel-image-container">
                <img
                  className="img-fluid"
                  onMouseOver={this.handleMouseOver}
                  onMouseLeave={this.handleMouseLeave}
                  onMouseMove={this.handleMouseMove}
                  src={photos[active].large}
                  alt=""
                />
                <div
                  style={{ display: zoomDisplay }}
                  className="zoom"
                >
                  <img
                    style={{
                      transform: `translate3d(${-PageX + 20}%, ${-PageY + 20}%, 0px)`,
                    }}
                    src={photos[active].full}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mt-3">
            {photos.map((photo, index) => (
              <div
                className="col-3 col-md-2 mb-3"
                key={index}
                style={{ maxWidth: "100px" }}
              >
                <img
                  onClick={this.handleIndexClick}
                  data-index={index}
                  className={`img-fluid ${index === active ? "active" : ""}`}
                  src={photo.large}
                  alt=""
                  style={{ cursor: "pointer" }}
                />
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}

export default ProductCarousel;
