import "bootstrap/dist/css/bootstrap.min.css";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import axios from "axios";
import LoginVendorRegister from "../../Components/LoginVendorRegister/Login";
import { Navigate } from "react-router";
import { useNavigate } from "react-router-dom";

const VendorRegister = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const initialValues = {
    name: "",
    email: "",
    phone: "",
    password: "",
    shop: "",
    photo: null,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    phone: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
    shop: Yup.string().required("Required"),
    photo: Yup.mixed().required("Image is required"),
  });

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (key === "photo" && values[key]) {
          formData.append(key, values[key]);
        } else {
          formData.append(key, values[key]);
        }
      });

      await axios.post(
        "https://ap.jakhantakhan.com/api/vendor-register",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // Handle success response
      alert("Vendor registered successfully!");
      navigate("/");
    } catch (error) {
      // Handle error response
      console.error("There was an error registering the vendor!", error);
      alert("Error registering vendor. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleImageChange = (event, setFieldValue) => {
    setFieldValue("photo", event.currentTarget.files[0]);
  };

  return (
    <div
      className="container mt-5"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div className="login-container">
        <LoginVendorRegister />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="d-flex flex-column align-items-center">
                  <label htmlFor="photo">
                    {values.photo ? (
                      <img
                        src={URL.createObjectURL(values.photo)}
                        alt="Preview"
                        style={{
                          width: "150px",
                          height: "150px",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <div
                        style={{
                          width: "150px",
                          height: "150px",
                          border: "1px dashed gray",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        No Image Found
                      </div>
                    )}
                  </label>
                  <input
                    id="photo"
                    name="photo"
                    type="file"
                    style={{ display: "none" }}
                    onChange={(event) =>
                      handleImageChange(event, setFieldValue)
                    }
                  />
                  <button
                    type="button"
                    className="btn btn-secondary mt-2"
                    onClick={() => document.getElementById("photo").click()}
                  >
                    Upload Image
                  </button>
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="name">Name *</label>
                <Field
                  name="name"
                  type="text"
                  className="form-control"
                  placeholder="User Name"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email *</label>
                <Field
                  name="email"
                  type="email"
                  className="form-control"
                  placeholder="Email Address"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone *</label>
                <Field
                  name="phone"
                  type="text"
                  className="form-control"
                  placeholder="Phone Number"
                />
                <ErrorMessage
                  name="phone"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password *</label>
                <Field
                  name="password"
                  type="password"
                  className="form-control"
                  placeholder="Password"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="shop">Shop</label>
                <Field name="shop" as="select" className="form-control">
                  <option value="">Select</option>
                  <option value="shop1">Sopnobash Shop</option>
                  <option value="shop2">Ariful Shop </option>
                </Field>
                <ErrorMessage
                  name="shop"
                  component="div"
                  className="text-danger"
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary mt-3"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Create Vendor"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default VendorRegister;
