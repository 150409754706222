import React, { useEffect, useState } from "react";
import CartBannerComponent from "./CartBannerComponent";
import VendorDetailsMain from "./VendorDetailsMain";
import "./index.css";

const VendorBanners = ({ vendorId,t }) => {
  const [vendorDetails, setVendorDetails] = useState(null);

  const [vendorProducts, setVendorProducts] = useState([]);

  useEffect(() => {
    fetch("https://ap.jakhantakhan.com/api/vendor/" + vendorId)
      .then((response) => response.json())
      .then((data) => {
        setVendorDetails(data);
        setVendorProducts(data.products);
      });
  }, []);

  return (
    <>
      {vendorDetails ? (
        <>
          <CartBannerComponent title={vendorDetails.name} />
          <div className="container">
            <VendorDetailsMain
              title={vendorDetails.name}
              products={vendorProducts}
              t={t}
            />
          </div>
        </>
      ) : (
        <h1>Loading...</h1>
      )}
    </>
  );
};

export default VendorBanners;
