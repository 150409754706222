import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "./index.css";

const CategoriesBar = ({ t }) => {
  const [showSubcat, setShowSubcat] = useState(false);
  const [categories, setCategories] = useState([]);

  const navigate= useNavigate();

  useEffect(() => {
    axios
    .get("https://ap.jakhantakhan.com/api/allcategories")
    .then(function (response) {
      // handle success
      console.log(response.data);
      const respData = response.data;
      respData.reverse();
      setCategories(respData);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    });
  }, [])
  

 

  console.log("categories", categories);

  const goToCategoryDetails=(ev, categoryId)=> {
    debugger;
    ev.preventDefault();
    navigate("/categoryDetails/"+categoryId);
  }
  const toggleSubcat = () => {
    setShowSubcat(!showSubcat);
  };

  return (
    <>
    <div class="sale-banner">
        Sale $20 Off Your First Order.
    </div>
    <nav class="navbar" style={{margin:"10px"}}>
    <div className="browse-categories">
      <button onClick={toggleSubcat} className="browse-button">
        Browse All Categories
      </button>
      {showSubcat && (
        <div className="subCats">
          <ul className="items">
            <li>
              <Link onClick={toggleSubcat} to={"/categoryDetails/2"}>{t("hotDeals")} </Link>
              <i className="fa fa-chevron-right"></i>
              <div className="mega-subCat-left collect">
                {/* Mega menu content */}
              </div>
            </li>
            <li>
              <Link onClick={toggleSubcat} to={"/categoryDetails/4"}>{t("elComp")} </Link>
              <i className="fa fa-chevron-right"></i>
              <div className="mega-subCat-left collect">
                {/* Mega menu content */}
              </div>
            </li>
            {categories.length > 0 &&
              categories.map((item) => (
                <li key={item.slug}>
                  <Link onClick={toggleSubcat} to={`/categoryDetails/${item.slug}`}>
                    {item.name}
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      )}
      </div>
        <ul class="nav-links">
            <li> <Link to={'/'}>Home </Link></li>
            <li><a href="#">Shop</a></li>
            <li><a href="#">Product</a></li>
            <li><a href="#">Pages</a></li>
            <li><a href="#">Blog</a></li>
            <li><a href="#">Contact Us</a></li>
        </ul>
        <div class="navbar-right">
            <button class="buy-button">Buy Uninext!</button>
        </div>
    </nav>
    </>
  );
};

export default CategoriesBar;
