import React, { useEffect, useState } from "react";
import "./index.css";

import { connect } from 'react-redux';
import HotDealsCard from "./HotDealsCard";

const HotDeals = ({ t , vendor_name}) => {
  const [newArrivalProducts, setnewArrivalProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch("https://ap.jakhantakhan.com/api/hot-deal?vendor_name="+vendor_name)
      .then((response) => response.json())
      .then((data) => {setnewArrivalProducts(data); setLoading(false);});

  }, [vendor_name]);

  let productsToShow = <h1>Loading...</h1>;
  let heading = (
    <>
      <h1>{t("HotDeals")}</h1>
    </>
  );
  if (newArrivalProducts.length) {
    productsToShow = newArrivalProducts.slice(0, 20).map((product) => (
      <div className="col-md-3">
        <HotDealsCard
          productObj={product}
          title={product.name}
          price={`৳${product.price}`}
          image={product.photo}
          t={t}
        />
      </div>
    ));
  } else if(!loading) {
    productsToShow = <h1>No products to show</h1>;
  }

  return (
    <div className="container p-4 new-arrivals-container">
      <div className="d-flex align-items-center">{heading}</div>
      <div className="row gy-5">
        {productsToShow}
      </div>
    </div>
  );
};

const mapStateToProps=(state)=> {
  return {
    vendor_name: state.globalState.selectedProductVendorName,
  }
}

const mapDispatchToProps={}

export default connect(mapStateToProps, mapDispatchToProps)(HotDeals);
