import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { authHeader, numberWithCommas } from "../../utils";
import "./index.css";
import OrderDetails from "../OrderDetails";

const ItemCard = ({ title, iconClass, itemValue, onClick }) => {
  return (
    <div className="itemcard" onClick={onClick}>
      <div className="iconContainer">
        <i className={iconClass}></i>
      </div>
      <h4>{itemValue}</h4>
      <p>{title}</p>
    </div>
  );
};

const MyAccounts = ({ userInfo }) => {
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();

  const getOrders = (userId) => {
    axios
      .post(
        "https://ap.jakhantakhan.com/api/order-list-user",
        { user_id: userId },
        { headers: authHeader() }
      )
      .then((response) => {
        setOrders(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (userInfo.id) {
      getOrders(userInfo.id);
    }
  }, [userInfo]);

  const editProfile = () => {
    navigate("/edit");
  };

  // const orderDetails = () => {
  //   navigate("/order-details");
  // };

  return (
    <div className="container">
      <div className="row content">
        <div className="col-md-12 decoration cards-background">
          <h4 className="pb-4">My Dashboard</h4>
          <div className="row">
            <div className="col-md-3">
              <Link to={"/orderdetails"}>
                <ItemCard
                  title={"Total Order"}
                  iconClass="fa fa-shopping-cart"
                  itemValue={orders.length}
                />
              </Link>
            </div>
            <div className="col-md-3">
              <ItemCard
                title={"Total Wishlist"}
                iconClass="fa fa-heart"
                itemValue={0}
              />
            </div>
            <div className="col-md-3">
              <ItemCard
                title={"Total Download"}
                iconClass="fa fa-cloud-download"
                itemValue={0}
              />
            </div>
            <div className="col-md-3">
              <ItemCard
                title={"Total Transactions"}
                iconClass="fa fa-money"
                itemValue={0}
              />
            </div>
            <div className="col-md-3" onClick={editProfile}>
              <ItemCard
                title={"Edit Your Account Information"}
                iconClass="fa fa-user"
                itemValue={""}
              />
            </div>
            <div className="col-md-3">
              <ItemCard
                title={"Change Your Password"}
                iconClass="fa fa-key"
                itemValue={""}
              />
            </div>
            <div className="col-md-3">
              <ItemCard
                title={"Modify your address book entries"}
                iconClass="fa fa-address-book"
                itemValue={""}
              />
            </div>
            <div className="col-md-3">
              <ItemCard
                title={"Modify your wishlist"}
                iconClass="fa fa-heart"
                itemValue={""}
              />
            </div>
            <div className="col-md-3">
              <ItemCard
                title={"View your order history"}
                iconClass="fa fa-undo"
                itemValue={""}
              />
            </div>
            <div className="col-md-3">
              <ItemCard
                title={"Downloads"}
                iconClass="fa fa-cloud-download"
                itemValue={""}
              />
            </div>
            <div className="col-md-3">
              <ItemCard
                title={"Your Reward Points"}
                iconClass="fa fa-shield"
                itemValue={""}
              />
            </div>
            <div className="col-md-3">
              <ItemCard
                title={"View your return requests"}
                iconClass="fa fa-retweet"
                itemValue={""}
              />
            </div>
            <div className="col-md-3">
              <ItemCard
                title={"Total Transactions"}
                iconClass="fa fa-money"
                itemValue={""}
              />
            </div>
            <div className="col-md-3">
              <ItemCard
                title={"Recurring Payments"}
                iconClass="fa fa-usd"
                itemValue={""}
              />
            </div>
            <div className="col-md-3">
              <ItemCard
                title={"Subscribe / unsubscribe to newsletter"}
                iconClass="fa fa-envelope"
                itemValue={""}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    userInfo: state.auth.user,
  };
};

export default connect(mapStateToProps)(MyAccounts);
