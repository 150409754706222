import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { connect } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import LoginVendorRegister from "../../Components/LoginVendorRegister/Login";
import { authOperations } from "../../state/ducs/auth";
import './index.css';

const Login = ({
  loading,
  error,
  isAuthenticated,
  authRedirectPath,
  signIn,
  setAuthRedirectPath,
}) => {
  const navigate = useNavigate();

  const onContinueHandler = () => {
    navigate("/register");
  };

  const loginSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string()
      .min(6, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
  });

  const initialValues = {
    email: '',
    password: '',
  };

  const handleLoginSubmit = (values) => {
    signIn(values.email, values.password);
  };

  useEffect(() => {
    // Set a condition here upon which you want to redirect to which path
    setAuthRedirectPath("/");
  }, [setAuthRedirectPath]);

  if (isAuthenticated) {
    return <Navigate to={authRedirectPath} />;
  }

  return (
    <div>
      <div className="container">
        <div className="row content">
          <div className="decration" style={{ display: "flex", justifyContent: "center" }}>
            <div className="login-container" style={{ alignContent: "center" }}>
              <LoginVendorRegister/>
              <Formik
                initialValues={initialValues}
                validationSchema={loginSchema}
                onSubmit={handleLoginSubmit}
              >
                {({ isSubmitting, errors }) => (
                  <Form>
                    <div className="mb-3">
                      <label htmlFor="email">Email Address *</label>
                      <Field type="email" id="email" name="email" className="form-control" />
                      <ErrorMessage name="email" component="div" className="error-message" />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="password">Password *</label>
                      <Field type="password" id="password" name="password" className="form-control" />
                      <ErrorMessage name="password" component="div" className="error-message" />
                    </div>
                    {errors.submit && <div className="error-message">{errors.submit}</div>}
                    <Button type="submit" style={{ backgroundColor: "#b80ebb" }} className="login-button" disabled={isSubmitting}>
                      Login
                    </Button>
                  </Form>
                )}
              </Formik>
              <div className="text-center mt-3">
                If you don't have an account,{' '}
                <Link onClick={onContinueHandler} to='/register' style={{ color: "aqua" }}>Register</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath,
  };
};

const mapDispatchToProps = {
  signIn: authOperations.auth,
  setAuthRedirectPath: authOperations.setAuthRedirectPath,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
