import React from "react";
import { connect } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import "./index.css";
// import { Link } from "react-router-dom";
const TopHeader = ({ changeLn, t, setlogin, isAuthenticated }) => {
  const handleLanguageChange = (e) => {
    changeLn(e.target.value);
  };

  const navigate = useNavigate();

  const gotToMyAccount = () => {
    navigate("/myAccount");
  };

  return (
    <>
     
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TopHeader);
