import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { authHeader, numberWithCommas } from "../../utils";
// import "./index.css";

const Index = ({ userInfo }) => {
  const [orders, SetOrders] = useState([]);

  const getOrders = (userId) => {
    axios
      .post(
        "https://ap.jakhantakhan.com/api/order-list-user",
        { user_id: userId },
        { headers: authHeader() }
      )
      .then((response) => {
        SetOrders(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (userInfo.id) {
      getOrders(userInfo.id);
    }
  }, [userInfo]);

  return (
    <div className="container">
      <h4 className="pb-2">Latest Orders</h4>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Customer</th>
            <th>No. of Products</th>
            <th>Status</th>
            <th>Total</th>
            <th>Date Added</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {orders.length > 0 ? (
            orders.map((order) => (
              <tr key={order.id}>
                <td>{order.id}</td>
                <td>{userInfo.name}</td>
                <td>{order.order_product.length}</td>
                <td>{order.status}</td>
                <td>৳{numberWithCommas(order.pay_amount)}</td>
                <td>{order.updated_at.split("T")[0]}</td>
                <td>
                  <i className="fa fa-eye"></i>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7">No orders found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    userInfo: state.auth.user,
  };
};
export default connect(mapStateToProps)(Index);
